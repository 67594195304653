import React from "react"
import H2 from "../../core/H2"
import P from "../../core/P"

export const MultipleCTA = ({ MultipleCTABlock }) => {
  const { ctaLinks, title, subtitle } = MultipleCTABlock
  return (
    <div
      className="max-w-5xl mx-auto text-center py-12 px-4 bg-transparent"
      data-aos="fade-up"
    >
      <div>
        <H2 title={title} />
        <P title={subtitle} className="text-shark-400" />
      </div>
      <div
        className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-1 mt-8"
        data-aos="fade-up"
      >
        {ctaLinks?.map(data => (
          <div
            className="w-full flex justify-center py-2 px-4"
            key={Math.random()}
          >
            <div className="inline-flex">
              <a
                href={data.cta.url}
                target="_blank"
                rel="noreferrer"
                className="bg-blue-500 inline-flex relative px-8 py-3 text-base leading-6 font-medium text-white  hover:bg-blue-600 rounded"
              >
                {data.cta.title}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MultipleCTA
